





import Vue from 'vue'
import Component from 'vue-class-component'
import CommonPicklDetails from '@/components/common/PicklResponses.vue'

@Component({
  components: {
    CommonPicklDetails
  }
})
export default class NonGeoPicklDetails extends Vue {
  public userRole: any = 'staff'
  public userUrl: any = 'staff'
  public isBrand: boolean = false

  mounted () {
    let userData = this.$store.state.userData
    this.userRole = userData.role
    if (this.userRole === 'super-admin') {
      this.userUrl = 'sadmin'
    } else if (this.userRole === 'brand') {
      this.isBrand = true
      this.userUrl = 'brand'
    }
  }

  redirectToPicklsPage () {
    this.$router.go(-1)
  }
}
