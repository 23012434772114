var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-lg modal-dialog-centered" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h2", [_vm._v("User Response")]),
              _c("button", {
                ref: "popupCloseButton",
                staticClass: "close",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  "aria-label": "Close"
                }
              })
            ]),
            !_vm.loadingUserPicklDetails
              ? _c("div", { staticClass: "modal-body" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "tab-content" }, [
                    _c(
                      "div",
                      {
                        staticClass: "tab-pane fade in active",
                        attrs: { id: "pickl-details-section" }
                      },
                      [
                        _c("br"),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-xs-12 col-md-6" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "green-border-box white-bg text-center first-block"
                              },
                              [
                                _c("div", { staticClass: "top-part" }, [
                                  _c("div", { staticClass: "posRel" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grey-logo-circle company-logo"
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "img-in-circle",
                                          attrs: {
                                            src:
                                              _vm.userPickl.pickl.store_location
                                                .store.image + "?w=150&q=50"
                                          },
                                          on: {
                                            error: function($event) {
                                              _vm.userPickl.pickl.store_location.store.image =
                                                "/img/default_store_image.png"
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grey-logo-circle product-logo"
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "img-in-circle",
                                          attrs: {
                                            src:
                                              _vm.userPickl.pickl.brand.logo +
                                              "?w=150&q=50"
                                          },
                                          on: {
                                            error: function($event) {
                                              _vm.userPickl.pickl.brand.logo =
                                                "/img/pickl-logo.png"
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c("h4", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.userPickl.pickl.store_location
                                            .store.name
                                        )
                                      )
                                    ]),
                                    _c("h5", [
                                      _vm._v(
                                        _vm._s(_vm.userPickl.pickl.brand.name)
                                      )
                                    ])
                                  ])
                                ]),
                                _c("div", { staticClass: "middle-part" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "address",
                                      attrs: {
                                        title:
                                          _vm.userPickl.pickl.store_location
                                            .address
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.userPickl.pickl.store_location
                                              .address
                                          ) +
                                          "\n                  "
                                      )
                                    ]
                                  ),
                                  _vm.userPickl
                                    ? _c("div", { staticClass: "address" }, [
                                        _c("img", {
                                          staticClass: "mr3",
                                          attrs: {
                                            src: "/img/Alerts/pickl_icon.png"
                                          }
                                        }),
                                        _c("strong", [_vm._v("Picklr :")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.userPickl.user.name) +
                                            "\n                    "
                                        ),
                                        _c("br", {
                                          staticClass: "showOnMobile"
                                        }),
                                        !_vm.isBrand
                                          ? _c("span", [
                                              _c("img", {
                                                staticClass: "ml5 mr3",
                                                attrs: {
                                                  src:
                                                    "/img/Alerts/callGreen_icon.png"
                                                }
                                              }),
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm._f("phone")(
                                                      _vm.userPickl.user
                                                        .mobile_number
                                                    )
                                                  ) +
                                                  "\n                    "
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    : _c("div", { staticClass: "address" }, [
                                        _c("img", {
                                          staticClass: "mr3",
                                          attrs: {
                                            src: "/img/Alerts/pickl_icon.png"
                                          }
                                        }),
                                        _c("strong", [_vm._v("Picklr :")]),
                                        _vm._v(
                                          " Not accepted\n                  "
                                        )
                                      ])
                                ]),
                                _c("div", { staticClass: "bottom-part" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-xs-12 col-sm-6 pickl-date"
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "/img/Alerts/section_icon.png"
                                        }
                                      }),
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.userPickl.pickl.product.section
                                          ) +
                                          "\n                  "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-xs-12 col-sm-6 pickl-time"
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "/img/Picklr/time_icon.png"
                                        }
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.userPickl.start_date) +
                                          "\n                  "
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "clearfix" })
                                ])
                              ]
                            ),
                            _vm.userPickl.pickl.has_multiple_products == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "green-border-box in-padd product-details"
                                  },
                                  _vm._l(_vm.userPickl.pickl.products, function(
                                    product,
                                    index
                                  ) {
                                    return _c("div", { key: product.id }, [
                                      _c("h4", [
                                        _vm._v(
                                          "Product Page " +
                                            _vm._s(index + 1) +
                                            " : " +
                                            _vm._s(product.product.name)
                                        )
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "row attr-imgs" },
                                        [
                                          _c(
                                            "viewer",
                                            {
                                              attrs: {
                                                images: product.product.images,
                                                options: _vm.viewerOptions
                                              }
                                            },
                                            _vm._l(
                                              product.product.images,
                                              function(photo, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key:
                                                      product.product.id +
                                                      "-" +
                                                      index,
                                                    staticClass:
                                                      "col-xs-4 col-md-3 photo"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "img-contain"
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticClass:
                                                            "vertical-center-transform",
                                                          attrs: {
                                                            src:
                                                              photo.image +
                                                              _vm.lowSrcQuery,
                                                            alt:
                                                              photo.description ===
                                                              null
                                                                ? _vm.pickl
                                                                    .product
                                                                    .name
                                                                : photo.description
                                                          }
                                                        })
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "attr-time text-center",
                                                        attrs: {
                                                          title:
                                                            photo.description ||
                                                            ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              photo.description ||
                                                                " "
                                                            ) +
                                                            "\n                        "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  }),
                                  0
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "green-border-box in-padd product-details"
                                  },
                                  [
                                    _c(
                                      "h3",
                                      { staticClass: "plain-center-header" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.userPickl.pickl.product.name
                                          )
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "row attr-imgs" },
                                      [
                                        _c(
                                          "viewer",
                                          {
                                            attrs: {
                                              images:
                                                _vm.userPickl.pickl.product
                                                  .images,
                                              options: _vm.viewerOptions
                                            }
                                          },
                                          _vm._l(
                                            _vm.userPickl.pickl.product.images,
                                            function(photo, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key:
                                                    _vm.userPickl.pickl.product
                                                      .id +
                                                    "-" +
                                                    index,
                                                  staticClass:
                                                    "col-xs-4 col-md-3 photo"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "img-contain"
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "vertical-center-transform",
                                                        attrs: {
                                                          src:
                                                            photo.image +
                                                            _vm.lowSrcQuery,
                                                          alt:
                                                            photo.description ===
                                                            null
                                                              ? _vm.userPickl
                                                                  .pickl.product
                                                                  .name
                                                              : photo.description
                                                        }
                                                      })
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "attr-time text-center",
                                                      attrs: {
                                                        title:
                                                          photo.description ||
                                                          ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            photo.description ||
                                                              " "
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        _vm._l(_vm.dummyProductImages, function(
                                          n
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: "dummy-product-" + n,
                                              staticClass:
                                                "col-xs-6 col-md-4 photo"
                                            },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "img-contain missing-image"
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "attr-time text-center"
                                                },
                                                [_vm._v("NA")]
                                              )
                                            ]
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  ]
                                ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "green-border-box in-padd normal-text posRel issues"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "row" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "col-xs-12 mb20" },
                                      [
                                        _c("label", [_vm._v("Issues")]),
                                        _vm.userPickl &&
                                        _vm.userPickl.issues &&
                                        _vm.userPickl.issues.length > 0
                                          ? _c(
                                              "span",
                                              _vm._l(
                                                _vm.userPickl.issues,
                                                function(issue) {
                                                  return _c(
                                                    "li",
                                                    { key: issue.id },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(issue.issue) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _c("span", [_vm._v("None")])
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-xs-12 mb20" },
                                      [
                                        _c("label", [_vm._v("Notes")]),
                                        _vm.isSadmin
                                          ? _c("div", [
                                              _c("div", [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.userPickl.note ||
                                                        "None"
                                                    ) +
                                                    "\n                      "
                                                )
                                              ])
                                            ])
                                          : _c("div", [
                                              _vm.userPickl
                                                .show_notes_to_brand == 1
                                                ? _c("div", [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.userPickl.note ||
                                                            "None"
                                                        ) +
                                                        "\n                      "
                                                    )
                                                  ])
                                                : _c("div", [
                                                    _vm._v(
                                                      "\n                        None\n                      "
                                                    )
                                                  ])
                                            ])
                                      ]
                                    ),
                                    _vm._l(
                                      _vm.userPickl.pickl_answers,
                                      function(answer) {
                                        return _vm.userPickl.pickl_answers
                                          ? _c(
                                              "div",
                                              {
                                                key: answer.id,
                                                staticClass: "col-xs-6"
                                              },
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      answer.pickl_question
                                                        .question
                                                    )
                                                  )
                                                ]),
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(answer.answer) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      }
                                    )
                                  ],
                                  2
                                )
                              ]
                            ),
                            _vm.userPickl.status == "APPROVED"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "green-border-box",
                                    staticStyle: { padding: "3%" }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("Credits Given: "),
                                      _c("strong", [
                                        _vm._v(
                                          "$" +
                                            _vm._s(
                                              _vm.userPickl.credits_assigned
                                            )
                                        )
                                      ])
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "text-align": "right",
                                          float: "right"
                                        }
                                      },
                                      [
                                        _vm._v("Reimbursement Given: "),
                                        _c("strong", [
                                          _vm._v(
                                            "$" +
                                              _vm._s(
                                                _vm.userPickl
                                                  .reimbursement_credits
                                              )
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "green-border-box rating in-padd normal-text posRel"
                              },
                              [
                                !_vm.isBrand &&
                                _vm.userPickl.status === "COMPLETED"
                                  ? _c("div", { staticClass: "text-center" }, [
                                      _c("h5", [_vm._v("Rate the Picklr")]),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "rate-picklr",
                                          on: {
                                            mouseout: function($event) {
                                              _vm.hoverRating = 0
                                            }
                                          }
                                        },
                                        _vm._l(5, function(i) {
                                          return _c(
                                            "span",
                                            {
                                              key: i,
                                              on: {
                                                mouseover: function($event) {
                                                  _vm.hoverRating = i
                                                },
                                                click: function($event) {
                                                  _vm.rating = i
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.getImagePath(i)
                                                }
                                              })
                                            ]
                                          )
                                        }),
                                        0
                                      ),
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.adminNote,
                                            expression: "adminNote"
                                          }
                                        ],
                                        attrs: {
                                          maxlength: "120",
                                          placeholder: "Notes"
                                        },
                                        domProps: { value: _vm.adminNote },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.adminNote = $event.target.value
                                          }
                                        }
                                      }),
                                      _c("div", { staticClass: "col-md-6" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.ShowNotesToBrand,
                                              expression: "ShowNotesToBrand"
                                            }
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            checked: ""
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.ShowNotesToBrand
                                            )
                                              ? _vm._i(
                                                  _vm.ShowNotesToBrand,
                                                  null
                                                ) > -1
                                              : _vm.ShowNotesToBrand
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$a = _vm.ShowNotesToBrand,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.ShowNotesToBrand = $$a.concat(
                                                      [$$v]
                                                    ))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.ShowNotesToBrand = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.ShowNotesToBrand = $$c
                                              }
                                            }
                                          }
                                        }),
                                        _vm._v(
                                          "Show Notes To Brand\n                  "
                                        )
                                      ]),
                                      _c("div", { staticClass: "col-md-6" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.needReview,
                                              expression: "needReview"
                                            }
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.needReview
                                            )
                                              ? _vm._i(_vm.needReview, null) >
                                                -1
                                              : _vm.needReview
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$a = _vm.needReview,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.needReview = $$a.concat(
                                                      [$$v]
                                                    ))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.needReview = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.needReview = $$c
                                              }
                                            }
                                          }
                                        }),
                                        _vm._v(
                                          "Attention\n                    Needed\n                  "
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm.userPickl.status === "APPROVED" ||
                                (!_vm.isBrand &&
                                  _vm.userPickl.status === "REJECTED")
                                  ? _c(
                                      "div",
                                      { staticClass: "rated" },
                                      [
                                        _vm.userPickl.need_review == "Yes"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "text-center mt10"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "completion-time"
                                                  },
                                                  [_vm._v("ATTENTION NEEDED")]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _c("label", [_vm._v("Notes")]),
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.userPickl.admin_note || "None"
                                            ) +
                                            "\n\n                  "
                                        ),
                                        _c("br"),
                                        _c("br"),
                                        _vm.userPickl.admin_rating !== null
                                          ? _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.isBrand ? "Admin" : "You"
                                                ) + " rated to Picklr "
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._l(
                                          _vm.userPickl.admin_rating,
                                          function(n) {
                                            return _c("img", {
                                              key: n,
                                              attrs: {
                                                src:
                                                  "/img/Alerts/picklerating_icon.png"
                                              }
                                            })
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                !_vm.isBrand &&
                                _vm.userPickl.status === "REJECTED" &&
                                _vm.userPickl.can_reverse_reject_pickl == true
                                  ? _c(
                                      "div",
                                      { staticClass: "col-xs-12 nopadd" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "text-right" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn-lg-green",
                                                staticStyle: { width: "250px" },
                                                on: {
                                                  click: _vm.reverseRejectPickl
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      REVERSE REJECTION\n                    "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "col-xs-12 col-md-6" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "green-border-box in-padd first-block posRel"
                              },
                              [
                                _c("div", [
                                  _c(
                                    "h3",
                                    { staticClass: "plain-center-header" },
                                    [_vm._v("PICKL Journey")]
                                  ),
                                  _c("div", { staticClass: "text-center" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "border-circle",
                                        style: {
                                          color:
                                            _vm.$common.cardColors["PENDING"],
                                          borderColor:
                                            _vm.$common.cardColors["PENDING"]
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "vertical-center-transform"
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm._f("formattedDate")(
                                                    _vm.userPickl.start_date
                                                  )
                                                )
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.userPickl.start_time
                                                ) +
                                                "\n                      "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "circle-label" },
                                          [_vm._v("Launched")]
                                        )
                                      ]
                                    ),
                                    _vm.userPickl.user_pickl_log
                                      ? _c(
                                          "span",
                                          _vm._l(
                                            _vm.userPickl.user_pickl_log,
                                            function(log, index) {
                                              return _vm.isSadmin ||
                                                (_vm.userPickl.status !==
                                                  "COMPLETED" &&
                                                  _vm.userPickl.status !==
                                                    "ACCEPTED") ||
                                                index === 0
                                                ? _c(
                                                    "span",
                                                    {
                                                      key: log.id,
                                                      style: {
                                                        color:
                                                          _vm.$common
                                                            .cardColors[
                                                            log.type.toUpperCase()
                                                          ],
                                                        borderColor:
                                                          _vm.$common
                                                            .cardColors[
                                                            log.type.toUpperCase()
                                                          ]
                                                      }
                                                    },
                                                    [
                                                      _c("hr", {
                                                        staticClass:
                                                          "journey-string"
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "border-circle"
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "vertical-center-transform"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "formattedPhpDate"
                                                                    )(
                                                                      log.updated_at,
                                                                      "short"
                                                                    )
                                                                  )
                                                              ),
                                                              _c("br"),
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "formattedPhpTime"
                                                                    )(
                                                                      log.updated_at
                                                                    )
                                                                  ) +
                                                                  "\n                          "
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "circle-label"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getLogName(
                                                                    log.type
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                    _vm.userPickl.processing_time
                                      ? _c(
                                          "div",
                                          { staticClass: "text-center mt10" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "completion-time"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.userPickl
                                                      .processing_time
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "green-border-box posRel" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "attr-imgs" },
                                  [
                                    _c(
                                      "viewer",
                                      {
                                        attrs: {
                                          images: _vm.taskImages,
                                          options: _vm.viewerOptions
                                        }
                                      },
                                      [
                                        _vm._l(
                                          _vm.sorted_user_pickl_photos,
                                          function(photo, index) {
                                            return _c(
                                              "div",
                                              { key: photo.name },
                                              [
                                                photo.task
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-xs-6 col-md-4 photo"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "img-contain"
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticClass:
                                                                "vertical-center-transform",
                                                              attrs: {
                                                                src:
                                                                  photo.image +
                                                                  _vm.lowSrcQuery,
                                                                alt: _vm.getTaskImageName(
                                                                  photo
                                                                )
                                                              }
                                                            }),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "img-overlay"
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "img-count"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        index +
                                                                          1
                                                                      )
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "vertical-center-transform"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.getTaskImageName(
                                                                          photo
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "attr-time"
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticClass:
                                                                "mr3",
                                                              attrs: {
                                                                src:
                                                                  "/img/Alerts/timeGreen_icon.png"
                                                              }
                                                            }),
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formattedPhpTime"
                                                                  )(
                                                                    photo.created_at
                                                                  )
                                                                )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          }
                                        ),
                                        _vm._l(
                                          _vm.sorted_user_pickl_photos,
                                          function(photo, index) {
                                            return _c(
                                              "div",
                                              { key: photo.id },
                                              [
                                                !photo.task
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-xs-6 col-md-4 photo"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "img-contain"
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticClass:
                                                                "vertical-center-transform",
                                                              attrs: {
                                                                src:
                                                                  photo.image +
                                                                  _vm.lowSrcQuery,
                                                                alt: _vm.getTaskImageName(
                                                                  photo
                                                                )
                                                              }
                                                            }),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "img-overlay"
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "img-count"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        index +
                                                                          1
                                                                      )
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "vertical-center-transform"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.getTaskImageName(
                                                                          photo
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "attr-time"
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticClass:
                                                                "mr3",
                                                              attrs: {
                                                                src:
                                                                  "/img/Alerts/timeGreen_icon.png"
                                                              }
                                                            }),
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formattedPhpTime"
                                                                  )(
                                                                    photo.created_at
                                                                  )
                                                                )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          }
                                        )
                                      ],
                                      2
                                    ),
                                    _vm._l(_vm.dummyPicklImages, function(n) {
                                      return _c(
                                        "div",
                                        {
                                          key: "dummy-image-" + n,
                                          staticClass: "col-xs-6 col-md-4 photo"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "img-contain" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "img-overlay" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "img-count"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          6 -
                                                            _vm.dummyPicklImages +
                                                            n
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "vertical-center-transform"
                                                    },
                                                    [_vm._v("No Image")]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._m(1, true)
                                        ]
                                      )
                                    }),
                                    !_vm.isSadmin &&
                                    _vm.user_pickl_photos.length === 0
                                      ? _c("div", { staticClass: "overlay" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vertical-center-transform normal-text"
                                            },
                                            [
                                              _vm._v(
                                                "\n                      Task images not available\n                    "
                                              )
                                            ]
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm.userPickl.pickl.user_pickl_selfie
                                      ? _c(
                                          "div",
                                          { staticClass: "col-sm-12" },
                                          [
                                            _c("h2", [_vm._v("Selfie Image")]),
                                            _c("img", {
                                              staticClass: "img-responsive",
                                              staticStyle: {
                                                width: "80%",
                                                margin: "auto",
                                                border: "solid 1px gainsboro"
                                              },
                                              attrs: {
                                                src:
                                                  _vm.userPickl.pickl
                                                    .user_pickl_selfie.image +
                                                  "?w=250&q=100"
                                              },
                                              on: {
                                                error: function($event) {
                                                  _vm.userPickl.pickl.store_location.store.image =
                                                    "/img/default_store_image.png"
                                                }
                                              }
                                            }),
                                            _c("br")
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ]
                            ),
                            _vm.userPickl.status == "COMPLETED" && !_vm.isBrand
                              ? _c(
                                  "div",
                                  { staticClass: "green-border-box" },
                                  [
                                    _c("h3", { staticClass: "text-center" }, [
                                      _vm._v("Add Data Points")
                                    ]),
                                    _vm._l(_vm.taskDataPoints, function(
                                      taskDataPoint
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: taskDataPoint.id,
                                          staticClass: "col-sm-6"
                                        },
                                        [
                                          _c("label", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                taskDataPoint.data_point
                                              )
                                            }
                                          }),
                                          _c("br"),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: taskDataPoint.value,
                                                expression:
                                                  "taskDataPoint.value"
                                              },
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required",
                                                expression: "'required'"
                                              }
                                            ],
                                            class: {
                                              input: true,
                                              "is-danger": _vm.errors.has(
                                                "add-staff.value"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "value",
                                              placeholder: "Enter Value"
                                            },
                                            domProps: {
                                              value: taskDataPoint.value
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  taskDataPoint,
                                                  "value",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm.userPickl.status == "APPROVED" ||
                            _vm.userPickl.status == "REJECTED"
                              ? _c(
                                  "div",
                                  { staticClass: "green-border-box" },
                                  [
                                    _c("h3", { staticClass: "text-center" }, [
                                      _vm._v(" Data Points")
                                    ]),
                                    _vm._l(
                                      _vm.userPickl.pickl
                                        .task_data_point_answers,
                                      function(taskDataPoint) {
                                        return _c(
                                          "div",
                                          {
                                            key: taskDataPoint.id,
                                            staticClass: "col-sm-6",
                                            staticStyle: {
                                              "margin-bottom": "15px"
                                            }
                                          },
                                          [
                                            _c("label", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  taskDataPoint.task_data_point
                                                    .data_point
                                                )
                                              }
                                            }),
                                            _c("br"),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  taskDataPoint.text_answer
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      }
                                    )
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm.$store.state.userData.role != "staff"
                              ? _c("div", { staticClass: "green-border-box" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "row",
                                      staticStyle: {
                                        background: "#ecf1eb",
                                        padding: "1%"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "col-sm-12 pull-right" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.openForDiscussion,
                                                expression: "openForDiscussion"
                                              }
                                            ],
                                            staticClass: "inline-checkbox",
                                            attrs: {
                                              type: "checkbox",
                                              id: "open-for-discussion"
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.openForDiscussion
                                              )
                                                ? _vm._i(
                                                    _vm.openForDiscussion,
                                                    null
                                                  ) > -1
                                                : _vm.openForDiscussion
                                            },
                                            on: {
                                              change: [
                                                function($event) {
                                                  var $$a =
                                                      _vm.openForDiscussion,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.openForDiscussion = $$a.concat(
                                                          [$$v]
                                                        ))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.openForDiscussion = $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                    }
                                                  } else {
                                                    _vm.openForDiscussion = $$c
                                                  }
                                                },
                                                _vm.toggleDiscussionStatus
                                              ]
                                            }
                                          }),
                                          _c(
                                            "label",
                                            {
                                              staticStyle: {
                                                display: "inline"
                                              },
                                              attrs: {
                                                for: "open-for-discussion"
                                              }
                                            },
                                            [_vm._v("Task Open For Discussion")]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c("br"),
                                  _vm.userPicklComments.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          ref: "comment-box",
                                          staticClass:
                                            "comments-section col-sm-12",
                                          staticStyle: {
                                            "max-height": "45vh",
                                            "overflow-y": "auto"
                                          }
                                        },
                                        _vm._l(_vm.userPicklComments, function(
                                          comment
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: comment.id,
                                              staticClass: "row"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  class:
                                                    _vm.$store.state.userData
                                                      .id == comment.userId
                                                      ? "your-comment"
                                                      : "team-comment"
                                                },
                                                [
                                                  _c("label", [
                                                    _vm.$store.state.userData
                                                      .id != comment.userId
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              float: "left"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  comment.user
                                                                    .name
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticStyle: {
                                                          float: "right"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "humanReadableDate"
                                                            )(comment.createdAt)
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]),
                                                  _c("span", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        comment.comment
                                                      )
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    : _c(
                                        "h2",
                                        {
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  No Comments yet "
                                          ),
                                          _c("br"),
                                          _c("small", [
                                            _vm._v(
                                              "(be the first one to comment here!)"
                                            )
                                          ])
                                        ]
                                      ),
                                  _vm.$store.state.userData.role != "staff"
                                    ? _c(
                                        "div",
                                        { staticClass: "row comment-section" },
                                        [
                                          _c("hr"),
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-12" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.commentText,
                                                    expression: "commentText"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  placeholder:
                                                    "Type your comment here...",
                                                  type: "text"
                                                },
                                                domProps: {
                                                  value: _vm.commentText
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.commentText =
                                                      $event.target.value
                                                  }
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-12" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-positive pull-right",
                                                  on: { click: _vm.saveComment }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Comment\n                    "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ]),
                          !_vm.isBrand && _vm.userPickl.status == "COMPLETED"
                            ? _c("div", { staticClass: "col-xs-12 nopadd" }, [
                                _c("div", { staticClass: "col-xs-12" }, [
                                  _vm.userPickl.pickl.reimbursement_credits > 0
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.addReimbursementCredits,
                                            expression:
                                              "addReimbursementCredits"
                                          }
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "reimbursement-check"
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.addReimbursementCredits
                                          )
                                            ? _vm._i(
                                                _vm.addReimbursementCredits,
                                                null
                                              ) > -1
                                            : _vm.addReimbursementCredits
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a =
                                                _vm.addReimbursementCredits,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.addReimbursementCredits = $$a.concat(
                                                    [$$v]
                                                  ))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.addReimbursementCredits = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.addReimbursementCredits = $$c
                                            }
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.userPickl.pickl.reimbursement_credits > 0
                                    ? _c(
                                        "label",
                                        {
                                          staticStyle: { display: "inline" },
                                          attrs: { for: "reimbursement-check" }
                                        },
                                        [
                                          _vm._v(
                                            "Add $" +
                                              _vm._s(
                                                _vm.userPickl.pickl
                                                  .reimbursement_credits
                                              ) +
                                              " Reimbursement Credits"
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("br"),
                                  _c("br"),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.bonusCredits,
                                        expression: "bonusCredits"
                                      }
                                    ],
                                    staticStyle: {
                                      width: "50%",
                                      display: "inline",
                                      "margin-bottom": "0px"
                                    },
                                    attrs: {
                                      type: "text",
                                      placeholder: "Enter Bonus Credits"
                                    },
                                    domProps: { value: _vm.bonusCredits },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.bonusCredits = $event.target.value
                                      }
                                    }
                                  })
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-xs-4 text-right" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn-lg-grey",
                                        on: { click: _vm.rejectPickl }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  REJECT " +
                                            _vm._s(
                                              _vm.isBrand ? "brand" : "sadmin"
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-xs-4 text-left" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn-lg-green",
                                        on: { click: _vm.approvePickl }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  APPROVE\n                "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ])
                      ]
                    ),
                    _vm._m(2)
                  ])
                ])
              : _c("div", { staticClass: "modal-body" }, [
                  _c("h2", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("Loading Data...")
                  ])
                ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "nav nav-tabs" }, [
      _c("li", { staticClass: "active" }, [
        _c(
          "a",
          { attrs: { "data-toggle": "tab", href: "#pickl-details-section" } },
          [_vm._v("Pickl Details")]
        )
      ]),
      _c("li", [
        _c(
          "a",
          { attrs: { "data-toggle": "tab", href: "#survey-details-section" } },
          [_vm._v("Survey Details")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "attr-time" }, [
      _c("img", {
        staticClass: "mr3",
        attrs: { src: "/img/Alerts/timeGreen_icon.png" }
      }),
      _vm._v("\n                      Taken at")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "tab-pane fade", attrs: { id: "survey-details-section" } },
      [_c("br"), _c("div", { staticClass: "row" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }