<template>
  <div
    class="modal-dialog modal-dialog-centered modal-lg"
    v-if="pickl && Object.keys(pickl).length > 0"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h2>PICKL Responses Details</h2>
        <button type="button" class="close" @click="$router.go(-1)"></button>
      </div>
      <div class="modal-body">
        <button @click="sendPicklStatsReport" type="button" class="pull-right btn btn-positive">Export User Report</button>
        <br/> <ul class="nav nav-tabs">
          <li class="active">
            <a data-toggle="tab" href="#pickl-details-section">Pickl Details</a>
          </li>
          <li>
            <a data-toggle="tab" href="#survey-details-section">Pickl Stats</a>
          </li>
        </ul>
        <div class="tab-content">
          <div id="pickl-details-section" class="tab-pane fade in active">
            <br />
            <div class="row">
              <div class="col-xs-12 col-md-12">
                <div class="green-border-box">
                  <h3 style="margin-left:10px;margin-right:10px;">
                    <span style="float:left;"
                      >Brand: {{ pickl.brand.name }}</span
                    >
                    <img
                      :src="pickl.brand.logo + '?w=150&q=50'"
                      @error="pickl.brand.logo = '/img/pickl-logo.png'"
                      style="width:50px; margin-left:33%;"
                    />
                    <span class="pull-right" style="text-align:right;"
                      >Total Responses Requested: {{ pickl.response_counter
                      }}<br/>Responses Left: {{ pickl.responses_left }}</span><br/>
                      <span class="pull-right" v-if="pickl.responses_expired > 0" style="text-align:right;"
                      >Responses Expired: {{ pickl.responses_expired}}</span><br v-if="pickl.responses_expired > 0"/>
                      <!-- <span class="pull-right" v-if="!brand" style="text-align:right;"
                      >Average Completion Time: {{ pickl.average_completion_time}}</span> -->
                  </h3>
                </div>
                <div class="green-border-box" v-if="pickl.launch_type == 'retailer-any-location'">
                  <h3 style="margin-left:10px;margin-right:10px;">
                    <span style="float:left;"
                      ><strong>Targeted Cities:</strong></span>
                  </h3>
                  <br/>
                  <div class="col-sm-12">
                  |<span v-for="city in pickl.targeted_main_cities" :key="city.id" style="font-weight:bold; padding:5px;">{{city.name}}, {{city.state.name}} |</span>
                  <br/><br/>
                  </div>
                </div>
                <div
                  class="green-border-box "
                  style="padding:1%;"
                  v-if="!brand"
                >
                  <div class="row" style="margin-bottom:15px;">
                    <div class="col-sm-3">
                      <h3>
                        User Responses
                      </h3>
                    </div>
                    <div class="col-sm-9" style="margin-top:12px;">
                      <button
                        class="btn-positive btn-send-message"
                        data-toggle="modal"
                        data-target="#send-message-popup"
                        v-if="!brand && $store.state.userData.role!='staff'"
                      >Send Message</button>
                      <button
                        class="btn-positive btn-send-message"
                        data-toggle="modal"
                        data-target="#send-notification-popup"
                        v-if="!brand && $store.state.userData.role!='staff'"
                      >Send Notification</button>
                    </div>
                  </div>
                  <ul class="nav nav-tabs">
                    <li class="active">
                      <a data-toggle="tab" href="#awaiting-approval-tab"
                        >Awaiting Approval ({{pickl.completed_user_pickls.length}})</a
                      >
                    </li>
                    <li>
                      <a data-toggle="tab" href="#accepted-tab">Accepted ({{pickl.accepted_user_pickls.length}})</a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#approved-tab">Approved ({{approvedUserList.length}})</a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#rejected-tab">Rejected ({{pickl.rejected_user_pickls.length}})</a>
                    </li>
                  </ul>
                  <div class="tab-content">
                    <br />
                    <div
                      id="awaiting-approval-tab"
                      class="tab-pane fade in active"
                    >
                      <div v-if="pickl.completed_user_pickls.length > 0">
                      <div class="send-msg-check">
                        <input type="checkbox" @change="toggleSelectAllResponses($event,'COMPLETED')" id="select-all-awaiting-responses" style="margin-left:7px;"><label for="select-all-awaiting-responses" class="checkbox-label">Select All Awaiting Responses</label><br/>
                      </div>
                        <div
                          class="green-border-box picklr-item"
                          v-for="completedUserPickl in pickl.completed_user_pickls"
                          :key="completedUserPickl.id"
                        >
                          <div class="col-xs-12 col-sm-5 profile-details ">
                            <input type="checkbox" class="checkbox" @change="toggleUserIdInUserIdsArray($event, completedUserPickl.user_id)" :checked="existsInSelectedUserIds(completedUserPickl.user_id)" style="position:absolute;margin-left:-13px;margin-top:2px;"/>
                            <div class="green-border-box profile-photo">
                              <img
                                v-square-img
                                :src="completedUserPickl.user.image+ '?w=150&q=50'"
                                @error="defaultImage"
                              />
                            </div>
                            <div class="afterImage">
                              <div
                                class="profile-name"
                                v-autosize="{ target: 22, min: 16 }"
                              >
                                {{ completedUserPickl.user.name }}
                              </div>
                              <div
                                class="phone-number"
                                v-if="!brand"
                                v-autosize="{ target: 16, min: 16 }"
                              >
                                {{
                                  completedUserPickl.user.mobile_number | phone
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-xs-12 col-sm-3 date-time">
                            Completed On<br />
                            {{
                              completedUserPickl.created_at | formattedPhpTime
                            }}
                            | {{ completedUserPickl.created_at | fullDate }}
                          </div>
                          <div class="col-xs-12 col-sm-3 nopadd">
                            <button
                              class="btn-lg-green pull-right"
                              v-if="!brand"
                              data-target="#user-pickl-geo-details-popup"
                              data-toggle="modal"
                              @click="getUserPicklInfo(completedUserPickl.id)"
                              style="background:rgb(211, 107, 0);"
                            >
                              APPROVE/VIEW
                            </button>
                          </div>
                        </div>
                      </div>
                      <h3 v-else style="text-align:center">
                        No Awaiting approval pickl found!
                      </h3>
                    </div>
                    <div id="accepted-tab" class="tab-pane fade in">
                      <div v-if="pickl.accepted_user_pickls.length > 0">
                        <div class="send-msg-check">
                            <input type="checkbox" @change="toggleSelectAllResponses($event, 'ACCEPTED')" class="select-all-accepted-responses" style="margin-left:7px;"><label for="select-all-accepted-responses" class="checkbox-label">Select All Accepted Responses</label><br/>
                        </div>
                        <div
                          class="green-border-box picklr-item"
                          v-for="acceptedUserPickl in pickl.accepted_user_pickls"
                          :key="acceptedUserPickl.id"
                        >
                          <div class="col-xs-12 col-sm-5 profile-details ">
                          <input type="checkbox" class="checkbox" @change="toggleUserIdInUserIdsArray($event, acceptedUserPickl.user_id)" :checked="existsInSelectedUserIds(acceptedUserPickl.user_id)" style="position:absolute;margin-left:-13px;margin-top:2px;"/>
                            <div class="green-border-box profile-photo">
                              <img
                                v-square-img
                                :src="acceptedUserPickl.user.image+ '?w=150&q=50'"
                                @error="defaultImage"
                              />
                            </div>
                            <div class="afterImage">
                              <div
                                class="profile-name"
                                v-autosize="{ target: 22, min: 16 }"
                              >
                                {{ acceptedUserPickl.user.name }}
                              </div>
                              <div
                                class="phone-number"
                                v-if="!brand"
                                v-autosize="{ target: 16, min: 16 }"
                              >
                                {{
                                  acceptedUserPickl.user.mobile_number | phone
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-xs-12 col-sm-3 date-time">
                            Accepted On<br />
                            {{
                              acceptedUserPickl.updated_at | formattedPhpTime
                            }}
                            | {{ acceptedUserPickl.updated_at | fullDate }}
                          </div>
                          <div class="col-xs-12 col-sm-3 nopadd">
                            <button
                              class="btn-lg-green pull-right"
                              v-if="!brand"
                              data-target="#user-pickl-geo-details-popup"
                              data-toggle="modal"
                              @click="getUserPicklInfo(acceptedUserPickl.id)"
                            >
                              VIEW
                            </button>
                          </div>
                        </div>
                      </div>
                      <h3 v-else style="text-align:center">
                        No Accepted pickls found!
                      </h3>
                    </div>
                    <div id="approved-tab" class="tab-pane fade in">
                      <div v-if="pickl.approved_user_pickls.length > 0">
                        <div class="send-msg-check">
                            <input type="checkbox" @change="toggleSelectAllResponses($event, 'APPROVED')" class="select-all-approved-responses" style="margin-left:7px;"><label for="select-all-approved-responses" class="checkbox-label">Select All Approved Responses</label><br/>
                        </div>
                        <div
                          class="green-border-box picklr-item"
                          v-for="approvedUserPickl in approvedUserList"
                          :key="approvedUserPickl.id"
                        >
                          <div class="col-xs-12 col-sm-5 profile-details ">
                          <input type="checkbox" class="checkbox" @change="toggleUserIdInUserIdsArray($event, approvedUserPickl.user_id)" :checked="existsInSelectedUserIds(approvedUserPickl.user_id)" style="position:absolute;margin-left:-13px;margin-top:2px;"/>
                            <div class="green-border-box profile-photo">
                              <img
                                v-square-img
                                :src="approvedUserPickl.user.image+ '?w=150&q=50'"
                                @error="defaultImage"
                              />
                            </div>
                            <div class="afterImage">
                              <div
                                class="profile-name"
                                v-autosize="{ target: 22, min: 16 }"
                              >
                                {{ approvedUserPickl.user.name }}
                              </div>
                              <div
                                class="phone-number"
                                v-if="!brand"
                                v-autosize="{ target: 16, min: 16 }"
                              >
                                {{
                                  approvedUserPickl.user.mobile_number | phone
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-xs-12 col-sm-3 date-time">
                            Approved On<br />
                            {{
                              approvedUserPickl.updated_at | formattedPhpTime
                            }}
                            | {{ approvedUserPickl.updated_at | fullDate }}
                          </div>
                          <div class="col-xs-12 col-sm-3 nopadd">
                            <button
                              class="btn-lg-green pull-right"
                              v-if="!brand"
                              data-target="#user-pickl-geo-details-popup"
                              data-toggle="modal"
                              @click="getUserPicklInfo(approvedUserPickl.id)"
                            >
                              VIEW
                            </button>
                          </div>
                        </div>
                      </div>
                      <h3 v-else style="text-align:center">
                        No approved pickls found!
                      </h3>
                    </div>
                    <div id="rejected-tab" class="tab-pane fade in">
                      <div v-if="pickl.rejected_user_pickls.length > 0">
                        <div class="send-msg-check">
                            <input type="checkbox" @change="toggleSelectAllResponses($event, 'REJECTED')" class="select-all-rejected-responses" style="margin-left:7px;"><label for="select-all-rejected-responses" class="checkbox-label">Select All REJECTED Responses</label><br/>
                        </div>
                        <div
                          class="green-border-box picklr-item"
                          v-for="rejectedUserPickl in pickl.rejected_user_pickls"
                          :key="rejectedUserPickl.id"
                        >
                          <div class="col-xs-12 col-sm-5 profile-details ">
                            <input type="checkbox" class="checkbox" @change="toggleUserIdInUserIdsArray($event, rejectedUserPickl.user_id)" :checked="existsInSelectedUserIds(rejectedUserPickl.user_id)" style="position:absolute;margin-left:-13px;margin-top:2px;"/>
                            <div class="green-border-box profile-photo">
                              <img
                                v-square-img
                                :src="rejectedUserPickl.user.image+ '?w=150&q=50'"
                                @error="defaultImage"
                              />
                            </div>
                            <div class="afterImage">
                              <div
                                class="profile-name"
                                v-autosize="{ target: 22, min: 16 }"
                              >
                                {{ rejectedUserPickl.user.name }}
                              </div>
                              <div
                                class="phone-number"
                                v-if="!brand"
                                v-autosize="{ target: 16, min: 16 }"
                              >
                                {{
                                  rejectedUserPickl.user.mobile_number | phone
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-xs-12 col-sm-3 date-time">
                            Approved On<br />
                            {{
                              rejectedUserPickl.created_at | formattedPhpTime
                            }}
                            | {{ rejectedUserPickl.created_at | fullDate }}
                          </div>
                          <div class="col-xs-12 col-sm-3 nopadd">
                            <button
                              class="btn-lg-green pull-right"
                              v-if="!brand"
                              data-target="#user-pickl-geo-details-popup"
                              data-toggle="modal"
                              @click="getUserPicklInfo(rejectedUserPickl.id)"
                            >
                              VIEW
                            </button>
                          </div>
                        </div>
                      </div>
                      <h3 v-else style="text-align:center">
                        No rejected pickls found!
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="green-border-box " style="padding:1%;" v-else>
                  <h3>
                    User Responses
                  </h3>
                  <ul class="nav nav-tabs">
                    <li class="active">
                      <a data-toggle="tab" href="#all-tab"
                        >All ({{pickl.approved_user_pickls.length}})</a
                      >
                    </li>
                    <li>
                      <a data-toggle="tab" href="#attention-needed-tab">Attention Needed</a>
                    </li>
                  </ul>
                  <div class="tab-content">
                    <br />
                    <div
                      id="all-tab"
                      class="tab-pane fade in active"
                    >
                      <div v-if="pickl.approved_user_pickls.length > 0">
                        <div
                          class="green-border-box picklr-item"
                          v-for="approvedUserPickl in pickl.approved_user_pickls"
                          :key="approvedUserPickl.id"
                        >
                          <div class="col-xs-12 col-sm-2 profile-details ">
                            <div class="green-border-box profile-photo">
                              <img
                                v-square-img
                                :src="approvedUserPickl.user.image+ '?w=150&q=50'"
                                @error="defaultImage"
                              />
                            </div>

                          </div>
                          <div class="col-xs-12 col-sm-3 date-time">
                            Credits Given<br />
                            ${{approvedUserPickl.credits_assigned}}

                          </div>
                          <div class="col-xs-12 col-sm-3 date-time">
                            Completed On<br />
                            {{
                              approvedUserPickl.updated_at | formattedPhpTime
                            }}
                            | {{ approvedUserPickl.updated_at | fullDate }}
                          </div>
                          <div class="col-xs-12 col-sm-3 nopadd">
                            <button
                              class="btn-lg-green pull-right"
                              data-target="#user-pickl-geo-details-popup"
                              data-toggle="modal"
                              @click="getUserPicklInfo(approvedUserPickl.id)"
                            >
                              VIEW
                            </button>
                          </div>
                        </div>
                      </div>
                      <h3 v-else style="text-align:center">
                        No completed pickls found!
                      </h3>
                    </div>
                    <div
                      id="attention-needed-tab"
                      class="tab-pane fade in"
                    >
                      <div v-if="pickl.approved_user_pickls.length > 0">
                         <div v-for="approvedUserPickl in pickl.approved_user_pickls"
                          :key="approvedUserPickl.id">
                        <div
                          class="green-border-box picklr-item"
                          v-if="approvedUserPickl.need_review == 'Yes'"
                        >
                          <div class="col-xs-12 col-sm-5 profile-details ">
                            <div class="green-border-box profile-photo">
                              <img
                                v-square-img
                                :src="defaultImage"
                                @error="defaultImage"
                              />
                            </div>
                            <div class="afterImage">
                              <div
                                class="profile-name"
                                v-autosize="{ target: 22, min: 16 }"
                              >
                                {{ approvedUserPickl.user.name }}
                              </div>
                              <div
                                class="phone-number"
                                v-if="!brand"
                                v-autosize="{ target: 16, min: 16 }"
                              >
                                {{
                                  approvedUserPickl.user.mobile_number | phone
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-xs-12 col-sm-3 date-time">
                            Completed On<br />
                            {{
                              approvedUserPickl.updated_at | formattedPhpTime
                            }}
                            | {{ approvedUserPickl.updated_at | fullDate }}
                          </div>
                          <div class="col-xs-12 col-sm-3 nopadd">
                            <button
                              class="btn-lg-green pull-right"
                              data-target="#user-pickl-geo-details-popup"
                              data-toggle="modal"
                              @click="getUserPicklInfo(approvedUserPickl.id)"
                            >
                              VIEW
                            </button>
                          </div>
                        </div>
                         </div>

                      </div>
                      <h3 v-else style="text-align:center">
                        No completed pickls found!
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="survey-details-section" class="tab-pane fade">
            <br />
            <PicklStats
              :surveyId="surveyId"
              :basicSurveyDetails="basicSurveyDetails"
              :brandId="brandId"
              :totalResponsesAnswers="totalResponsesAnswers"
              :pickl="pickl"
            />
          </div>
        </div>
      </div>
    </div>
    <UserPicklDetailsPopup
      :popupId="'user-pickl-geo-details-popup'"
      :brand="brand"
      :userPickl="userPickl"
      :loadingUserPicklDetails="loadingUserPicklDetails"
      @picklStatusUpdated="getMultipleUserPicklDetails"
      :isBrand="isBrand"
    />
    <SendMessagePopup
      popupId="send-message-popup"
      :checkedUsersList="selectedUserIds"
      :excludeResponders="excludeResponders"
      :picklId="pickl.id"
      @messageSent="messageSent"
      :selectAllPicklrs="false"
      :picklrFilters="{}"
    />
      <SendCustomNotificationPopup
      popupId="send-notification-popup"
      :checkedUsersList="selectedUserIds"
      :excludeResponders="excludeResponders"
      :picklId="pickl.id"
      @notificationSent="messageSent"
      :selectAllPicklrs="false"
      :picklrFilters="{}"
    />
    </div>
</template>

<script>
import UserPicklDetailsPopup from '@/components/common/UserPicklDetailsPopup.vue'
import PicklStats from '@/components/common/NonGeoPicklStats.vue'

import NonGeoUserPicklDetailsPopup from '@/components/common/NonGeoUserPicklDetailsPopup.vue'
import SendMessagePopup from '@/components/SAdmin/SendMessagePopup.vue'
import SendCustomNotificationPopup from '@/components/SAdmin/SendCustomNotificationPopup.vue'

export default {
  components: {
    UserPicklDetailsPopup,
    NonGeoUserPicklDetailsPopup,
    SendMessagePopup,
    SendCustomNotificationPopup,
    PicklStats
  },
  name: 'PicklResponses',
  data: function () {
    return {
      isBrand: true,
      userPickl: {},
      connectedTaskUserIds: [],
      excludeResponders: false,
      selectedUserIds: [],
      loadingUserPicklDetails: true,
      selectedUserPickl: { pickl_survey_answers: [] },
      pickl: {},
      userPicklId: 0,
      surveyId: this.$route.params.surveyId,
      basicSurveyDetails: {},
      brandId: 0,
      totalResponsesAnswers: 0,
      hoverRating: 0,
      rating: 0,
      adminNote: '',
      needReview: '',
      ShowNotesToBrand: 1,
      defaultImage: '/img/pickl-logo.png',
      lowSrcQuery: '?w=200&q=80&h=200&fit=crop',
      viewerOptions: {
        toolbar: {
          zoomIn: 1,
          zoomOut: 1,
          oneToOne: 0,
          reset: 0,
          prev: {
            size: 'large'
          },
          play: 0,
          next: {
            size: 'large'
          },
          rotateLeft: 1,
          rotateRight: 1,
          flipHorizontal: 0,
          flipVertical: 0
        },
        filter: function (image) {
          return !image.src.endsWith('/img/Alerts/timeGreen_icon.png')
        },
        title: [
          1,
          function (image, imageData) {
            return image.alt
          }
        ],
        url: image => {
          return image.src.replace(this.lowSrcQuery, '')
        }
      }
    }
  },
  props: {
    popupId: String,
    brand: false
  },

  created () {
    this.$store.dispatch('getAppSettingsKeyValuePair')
    if (this.$store.state.userData.role === 'super-admin') {
      this.brandId = this.$route.params.brandId
    } else {
      this.brandId = this.$store.state.userData.brandId
    }
  },
  mounted () {
    this.getMultipleUserPicklDetails()
    let userData = this.$store.state.userData
    this.userRole = userData.role
    if (this.userRole === 'super-admin') {
      this.userUrl = 'sadmin'
      this.isBrand = false
    } else if (this.userRole === 'brand') {
      this.isBrand = true
      this.userUrl = 'brand'
    }
    console.log(this.isBrand)
  },
  methods: {
    messageSent () {
      this.selectedUserIds = []
      this.getCountries()
    },
    existsInSelectedUserIds (userId) {
      return this.selectedUserIds.includes(userId)
    },

    toggleUserIdInUserIdsArray (e, userId) {
      if (e.target.checked) {
        if (this.selectedUserIds.indexOf(userId) === -1) {
          this.selectedUserIds.push(userId)
        }
      } else {
        let index = this.selectedUserIds.indexOf(userId)
        if (index !== -1) {
          this.selectedUserIds.splice(index, 1)
        }
      }
    },

    toggleSelectAllResponses (e, type) {
      if (e.target.checked) {
        if (type === 'COMPLETED') {
          this.pickl.completed_user_pickls.forEach((i) => {
            if (this.selectedUserIds.indexOf(i.user_id) === -1) {
              this.selectedUserIds.push(i.user_id)
            }
          })
        } else if (type === 'APPROVED') {
          this.approvedUserList.forEach((i) => {
            if (this.selectedUserIds.indexOf(i.user_id) === -1) {
              this.selectedUserIds.push(i.user_id)
            }
          })
        } else if (type === 'REJECTED') {
          this.pickl.rejected_user_pickls.forEach((i) => {
            if (this.selectedUserIds.indexOf(i.user_id) === -1) {
              this.selectedUserIds.push(i.user_id)
            }
          })
        } else if (type === 'ACCEPTED') {
          this.pickl.accepted_user_pickls.forEach((i) => {
            if (this.selectedUserIds.indexOf(i.user_id) === -1) {
              this.selectedUserIds.push(i.user_id)
            }
          })
        }
      } else {
        if (type === 'COMPLETED') {
          this.pickl.completed_user_pickls.forEach((i) => {
            let index = this.selectedUserIds.indexOf(i.user_id)
            if (index !== -1) {
              this.selectedUserIds.splice(index, 1)
            }
          })
        } else if (type === 'APPROVED') {
          this.pickl.approved_user_pickls.forEach((i) => {
            let index = this.selectedUserIds.indexOf(i.user_id)
            if (index !== -1) {
              this.selectedUserIds.splice(index, 1)
            }
          })
        } else if (type === 'REJECTED') {
          this.pickl.rejected_user_pickls.forEach((i) => {
            let index = this.selectedUserIds.indexOf(i.user_id)
            if (index !== -1) {
              this.selectedUserIds.splice(index, 1)
            }
          })
        } else if (type === 'ACCEPTED') {
          this.pickl.accepted_user_pickls.forEach((i) => {
            let index = this.selectedUserIds.indexOf(i.user_id)
            if (index !== -1) {
              this.selectedUserIds.splice(index, 1)
            }
          })
        }
      }
    },
    sendPicklStatsReport () {
      let userType = 'brand'
      if (!this.brand) {
        userType = 'sadmin'
      }
      this.$store
        .dispatch('exportPicklInsights', { picklId: this.pickl.id, userType: userType })
        .then(response => {
          this.$notify({ type: 'success', text: response.body.message })
        })
    },
    getUserPicklInfo (userPicklId) {
      this.loadingUserPicklDetails = true
      this.$store
        .dispatch('getUserPicklDetails', { userPicklId: userPicklId })
        .then(response => {
          this.userPickl = response.response.body
          this.loadingUserPicklDetails = false
        })
    },
    getMultipleUserPicklDetails () {
      this.$store
        .dispatch('getMultipleResponsePicklDetails', {
          picklId: this.$route.params.picklId
        })
        .then(response => {
          this.pickl = response.response.body
          this.getSurveyById()
        })
    },
    getTotalResponses () {
      this.basicSurveyDetails.surveyQuestions.forEach((i, j) => {
        this.totalResponsesAnswers =
          this.totalResponsesAnswers + i.totalResponse
      })
    },

    getSurveyById () {
      this.$http
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/brands/${this.pickl.brand_id}/pickls/${this.pickl.id}/surveys/${this.pickl.survey_id}/stats?includeAnswers=true&includeQuestions=true&includeSurveyStats=true`
        )
        .then(
          response => {
            this.basicSurveyDetails = response.body
            this.getTotalResponses()
          },
          response => {
          }
        )
    },

    imageLoadOnError () {
      this.picklr.image = '/img/pickl-logo.png'
    },

    inPicklSurveyAnswers (answerId) {
      let status = false
      this.pickl.pickl_survey_answers.forEach(x => {
        if (x.answer_id === answerId) {
          status = true
          return true
        }
      })
      return status
    },

    redirectToPicklsPage () {},
    getLogName: function (type) {
      if (this.$store.state.userData && this.isSadmin && type === 'completed') {
        return 'Awaiting Approval'
      } else return type
    },
    getImagePath: function (i) {
      if (this.hoverRating > 0) {
        if (i <= this.hoverRating) return '/img/Alerts/starActive_icon.png'
        else return '/img/Alerts/star_icon.png'
      } else if (i <= this.rating) return '/img/Alerts/starActive_icon.png'
      else return '/img/Alerts/star_icon.png'
    },
    // reverseRejectPickl () {
    //   let data = { admin_rating: this.rating, admin_note: this.adminNote, need_review: (this.needReview) ? 'Yes' : 'No' }
    //   this.$http
    //     .patch('user_pickls/' + this.pickl.user_pickl.id + '/REVERSE_REJECTED', data)
    //     .then((response) => {
    //       this.$notify({ type: 'success', text: 'Pickl rejection has be reversed successfully!' })
    //       this.$emit('reversedRejection')
    //     }, response => {
    //       this.$notify({ type: 'error', text: 'Failed to save pickl status' })
    //     })
    // },
    getPicklDetails () {
      this.$refs.closePicklDetailsPopupButton.click()
      this.$emit('getPicklDetails', this.pickl.id)
    }
  },
  computed: {

    approvedUserList () {
      if (this.excludeResponders) {
        let updatedApprovedPickls = []
        this.pickl.approved_user_pickls.forEach(x => {
          if (!this.connectedTaskUserIds.includes(x.user_id)) {
            updatedApprovedPickls.push(x)
          }
        })

        return updatedApprovedPickls
      } else {
        return this.pickl.approved_user_pickls
      }
    },

    totalAppInstalled () {
      let count = 0
      this.pickl.approved_user_pickls.forEach((i) => {
        if (i.app_installed === 0) {
          count++
        }
      })
      return count
    },
    alreadyAppInstalled () {
      let count = 0
      this.pickl.approved_user_pickls.forEach((i) => {
        if (i.app_installed === 1) {
          count++
        }
      })
      return count
    },

    isSadmin () {
      return (
        this.$store.state.userData &&
        (this.$store.state.userData.role === 'super-admin' ||
          this.$store.state.userData.role === 'staff')
      )
    },
    dummyPicklImages () {
      if (this.user_pickl_photos.length > 0) {
        let count = 6 - this.user_pickl_photos.length
        if (count < 0) {
          return 0
        } else {
          return count
        }
      } else {
        return 6
      }
    },
    dummyProductImages () {
      if (this.pickl.product.images && this.pickl.product.images.length > 0) {
        let count = 6 - this.pickl.product.images.length
        if (count < 0) {
          return 0
        } else {
          return count
        }
      } else {
        return 6
      }
    },
    user_pickl_photos () {
      if (
        this.pickl &&
        this.pickl.user_pickl &&
        this.pickl.user_pickl.user_pickl_photos &&
        this.$store.state.userData &&
        (this.isSadmin || this.pickl.status === 'APPROVED')
      ) {
        return this.pickl.user_pickl.user_pickl_photos
      } else {
        return []
      }
    },
    productImages () {
      let images = []
      if (
        this.pickl &&
        this.pickl.product &&
        this.pickl.product.images &&
        this.pickl.product.images.length > 0
      ) {
        this.pickl.product.images.forEach(x => {
          images.push({
            path: x.image,
            caption:
              x.description === null ? this.pickl.product.name : x.description
          })
        })
      }
      return images
    },
    taskImages () {
      let images = []
      this.user_pickl_photos.forEach(x => {
        images.push({
          path: x.image,
          caption: x.task ? x.task.name : 'Extra Image'
        })
      })
      return images
    }
  }
}
</script>

<style scoped>
.picklr-item {
  float: left;
  width: 98%;
  margin: 5px;
  overflow: hidden;
}

.profile-details .profile-photo {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 15px;
  vertical-align: top;
  display: inline-block;
  position: relative;
  margin-top: 11px;
}

.profile-details .profile-photo img {
  position: absolute;
}

.profile-details .afterImage {
  width: calc(100% - 85px);
  display: inline-block;
  padding: 10px 10px 10px 0;
}

.profile-details .profile-name {
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.8);
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 30px;
  overflow: hidden;
}

.profile-details .rating img {
  width: 13px;
  height: 12px;
  margin-right: 3px;
}

.part {
  display: inline-block;
  width: 33.333%;
  text-align: center;
  padding: 32px 0;
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
}

.part-white {
  background: #fff;
  border-left: 1px solid #ededed;
}

.part-grey {
  background: #f7f8f9;
}

.part h4 {
  color: rgba(0, 0, 0, 0.8);
  font-size: 23px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 11px;
}

.due-alert h4,
.due-alert span {
  color: #e34917;
}

.due-alert span {
  border-radius: 360px;
  border: 1px solid #e34917;
  padding: 0 18px;
  line-height: 20px;
  display: inline-block;
}

.date-time {
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  line-height: 30px;
  vertical-align: middle;
  padding: 10px 0;
}

.contact-number {
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
}
.vertical-center-transform {
  width: 100%;
  text-align: center;
}
label.checkbox-label {
    display: inline;
}

.green-border-box {
  margin-bottom: 15px;
}

.border-circle {
  width: 19%;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  display: inline-block;
  margin-bottom: 44px;
}

.border-circle:before {
  content: ' ';
  padding-top: 100%;
  display: block;
}

hr.journey-string {
  width: 5%;
  margin: 10% 4px 0;
  vertical-align: top;
  border-width: 2px;
  border-color: #d9d9d9;
  display: inline-block;
}

.modal-content .green-border-box .plain-center-header {
  font-weight: 500;
  font-size: 22px;
  color: #333333;
  margin-top: 0;
  margin-bottom: 15px;
  text-align: center;
}

.circle-label {
  font-size: 16px;
  color: black;
  width: 100%;
  text-align: center;
  display: block;
  position: absolute;
  bottom: -50px;
  left: 0;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 22px;
  height: 44px;
  overflow: hidden;
}

.circle-label:last-child {
  padding-right: 0;
}

.completion-time {
  border-radius: 360px;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 13px;
  padding: 5px 15px;
  background: #f02a38;
  background: -webkit-linear-gradient(left, #f02a38 0%, #ff434c 100%);
  background: -moz-linear-gradient(left, #f02a38 0%, #ff434c 100%);
  background: linear-gradient(to right, #f02a38 0%, #ff434c 100%);
}

.rating img:last-child {
  margin-right: 0;
}

.rating strong {
  font-weight: 500;
  font-size: 18px;
  color: black;
  margin-right: 5px;
}

.top-part {
  padding: 15px;
}

.middle-part {
  padding: 0 15px 15px;
}

.top-part h4,
.top-part h5 {
  padding: 0 75px;
  margin: 0;
}

.top-part h4 {
  font-weight: 500;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 30px;
}

.top-part h5 {
  font-size: 17px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
  margin-bottom: 8px;
}

.product-logo {
  left: auto;
  right: 0;
}

.middle-part .address {
  font-size: 17px;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}

/* .middle-part .address:first-of-type {
  min-height: 48px;
} */

.middle-part .status {
  font-size: 19px;
  font-weight: 500;
}

.bottom-part {
  padding: 10px 0;
  background: #f7f8f9;
  font-weight: 500;
  font-size: 19px;
  color: rgba(0, 0, 0, 0.8);
  vertical-align: text-top;
  line-height: 35px;
}

.bottom-part img {
  vertical-align: text-top;
  margin-right: 3px;
}

.bottom-part .pickl-date {
  border-right: 1px solid #cfd1d3;
}

.rating {
  background-color: #f7f8f9;
  /* padding: 30px 20px; */
  float: left;
  width: 100%;
  min-height: 233px;
}

.issues {
  min-height: 233px;
}

.rating img {
  width: 19px;
  height: 17px;
  margin-right: 3px;
}

.rating img:last-child {
  margin-right: 0;
}

.attr-imgs {
  padding: 20px 10px 0;
}

.photo {
  padding: 0 10px;
  margin-bottom: 40px;
}

.img-contain {
  position: relative;
  margin-bottom: 10px;
  display: block;
  width: 100%;
  overflow: hidden;
}

.img-contain:before {
  padding-top: 100%;
  display: block;
  content: ' ';
}

.img-contain img {
  width: 100%;
  height: auto;
  cursor: pointer;
  /* max-width: 100%;
  max-height: 100%; */
}

.img-contain .img-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  /* background-color: rgba(0, 0, 0, 0.3); */
  font-weight: 500;
  font-size: 22px;
  text-transform: capitalize;
  pointer-events: none;
}

.img-contain .img-overlay .img-count {
  border-radius: 50%;
  background-color: #00e05e;
  border: 2px solid white;
  font-size: 17px;
  width: 25px;
  height: 25px;
  display: block;
  text-align: center;
  position: absolute;
  top: 5px;
  left: 5px;
  line-height: 21px;
}

.attr-time {
  font-size: 17px;
  color: #333333;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.normal-text {
  color: rgba(0, 0, 0, 0.9);
  font-size: 18px;
}

label {
  width: 100%;
  color: rgba(89, 89, 89, 0.9);
  font-size: 17px;
  margin-top: 0;
  margin-bottom: 0;
}

.rating label {
  margin-top: 30px;
}

.btn-lg-grey,
.btn-lg-green {
  width: 200px;
}

textarea {
  margin-bottom: 0;
}

.rating h5 {
  font-weight: 500;
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 14px;
}

.rate-picklr {
  cursor: pointer;
  margin-bottom: 45px;
}

.rate-picklr span {
  display: inline-block;
}

.rate-picklr img {
  width: 33px;
  height: 31px;
  margin: 0 8px;
}

.showOnMobile {
  display: none;
}

.address img {
  vertical-align: text-top;
}

.img-contain .img-overlay span:last-child {
  padding: 3px;
  background: rgba(0, 0, 0, 0.8);
}

.overlay {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.overlay .vertical-center-transform {
  color: white;
}

.first-block {
  min-height: 230px;
}

.product-details .photo {
  margin-bottom: 14px;
}

.product-details .attr-imgs {
  padding-top: 0;
}

.product-details .missing-image {
  border: 1px dashed black;
}

@media only screen and (min-width: 1096px) and (max-width: 1199px) {
  .bottom-part {
    font-size: 15px;
    line-height: 30px;
  }

  .bottom-part > div {
    padding-left: 0;
    padding-right: 0;
  }

  .bottom-part img {
    height: 17px;
    width: auto;
  }
}
.modal-dialog.modal-lg {
  max-width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .border-circle {
    font-size: 13px;
    line-height: 17px;
  }

  .border-circle .circle-label {
    font-size: 13px;
  }

  .img-contain .img-overlay {
    font-size: 17px;
  }
}

@media only screen and (max-width: 767px) {
  .bottom-part {
    padding: 0 15px;
  }

  .bottom-part .pickl-date,
  .bottom-part .pickl-time {
    padding: 10px 0;
  }

  .bottom-part .pickl-date {
    border-bottom: 1px solid #cfd1d3;
    border-right: none;
  }

  .rating {
    min-height: auto;
  }

  .issues {
    min-height: auto;
  }
}

@media only screen and (max-width: 575px) {
  .company-logo {
    left: 20%;
  }

  .product-logo {
    right: 20%;
  }

  .top-part h4 {
    padding: 0;
    padding-top: 70px;
  }
  .top-part h5 {
    padding: 0;
  }

  .showOnMobile {
    display: inline;
  }

  /* .rating {
    padding: 15px;
  } */

  .rating strong {
    font-size: 16px;
  }

  .rated img {
    width: 15px;
    height: auto;
  }

  .img-contain .img-overlay {
    font-size: 17px;
  }

  .border-circle {
    font-size: 14px;
    line-height: 17px;
    min-width: 75px;
    min-height: 75px;
    display: block;
    margin-bottom: 0;
  }

  .border-circle .circle-label {
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 20px);
    width: auto;
    text-align: left;
    bottom: auto;
    height: auto;
  }

  hr.journey-string {
    width: 100%;
    border-width: 1px;
    margin: 25px 0;
  }

  .btn-lg-grey,
  .btn-lg-green {
    width: 120px;
  }
}

.review-attention strong {
  color: #cb4f40;
}
</style>
