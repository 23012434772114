var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pickl-details" },
    [
      _c("CommonPicklDetails", {
        attrs: { popupId: "pickl-details-popup", brand: _vm.isBrand },
        on: { statusUpdated: _vm.redirectToPicklsPage }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }